/**
 * 指定したlocaleが定義済みかを判別する
 */
export function isDefinedLocale(locale: string): locale is LocaleString {
  return locale === 'ja' || locale === 'en';
}

/**
 * 言語設定を取得する
 */
export function getUserLocale(): string {
  if (typeof userPreferences !== 'undefined' && userPreferences !== null) {
    return userPreferences.locale;
  }

  // fallback: ブラウザの設定を参照
  let locale = 'ja';
  if (navigator && navigator.language) {
    locale = navigator.language;
  } else if (navigator && (navigator as any).userLanguage) { // eslint-disable-line @typescript-eslint/no-explicit-any
    locale = (navigator as any).userLanguage; // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  return locale.split('-')[0].toLowerCase();
}

export function coalesceToDefinedLocale(locale: string, fallback?: LocaleString): LocaleString {
  return isDefinedLocale(locale) ? locale : (fallback ?? 'ja');
}
